import { useState, useEffect } from "react"
import cn from "classnames"
import { useRouter } from "next/router"
import Link from "next/link"
import MenuItem from "components/Header/MenuItem"
import CallToAction from "components/CallToAction"
import MenuButton from "public/assets/hamburger-menu.svg"
import Logo from "public/assets/header-logo.svg"
import getTelLink from "utilities/getTelLink"

export default function Header({ header, phoneNumbers }) {
  const [open, setMobileMenuVisibility] = useState(false)
  const [currentOpenSubmenu, setSubMenuAccordionVisibility] = useState("")

  const router = useRouter()

  // close the menu when a link is clicked
  useEffect(() => {
    const handleRouteChange = () => {
      setMobileMenuVisibility(false)
      setSubMenuAccordionVisibility("")
      document.activeElement && document.activeElement.blur()
    }
    router.events.on("routeChangeComplete", handleRouteChange)
    router.events.on("hashChangeComplete", handleRouteChange)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
      router.events.off("hashChangeComplete", handleRouteChange)
    }
  }, [router.events])

  // disable scroll when mobile menu is open
  useEffect(() => {
    document.querySelector("html").style.overflow = open ? "hidden" : ""
  }, [open])

  return (
    <header className="bg-navy py-6 px-8">
      <div className="max-w-screen-xl mx-auto flex items-center justify-between gap-7">
        <Link href="/" className="w-full max-w-xs">
          <Logo title="Return to home page" />
        </Link>
        <button
          className="xl:hidden"
          aria-label={open ? "close menu" : "open menu"}
          onClick={() => setMobileMenuVisibility((prev) => !prev)}
        >
          <MenuButton className={cn("mobile-menu-btn mt-1 cursor-pointer w-full", { open })} />
        </button>
        <div
          className={cn(
            "py-8 px-8 xl:p-0 absolute overflow-y-scroll xl:overflow-visible xl:static w-full h-[calc(100vh-110px)] xl:h-auto top-28 left-0 z-50 gap-6 xl:gap-0 bg-navy transition-all duration-500 origin-top xl:flex xl:flex-col xl:scale-y-100",
            {
              "scale-y-0": !open,
              "scale-y-100": open,
            }
          )}
        >
          <div className="flex xl:justify-end gap-8 py-6 xl:pt-0 xl:px-0 border-y-0.5 xl:border-t-0 border-blue w-full">
            <Link href={getTelLink(phoneNumbers?.Primary)} className="text-white underline hover:no-underline">
              {phoneNumbers?.Primary}
            </Link>
            <Link href="/patient-portal" className="text-white underline hover:no-underline">
              Patient Portal
            </Link>
          </div>
          <nav aria-label="Main" className="menu flex flex-col xl:flex-row mt-4 w-full justify-between">
            <ul aria-label="Top-level Menu Items" className="m-0 xl:flex w-full items-center justify-between">
              {header?.map((item) => (
                <MenuItem
                  {...{ currentOpenSubmenu, setSubMenuAccordionVisibility, item, currentPath: router.asPath }}
                  key={`menu-item-${item._uid}`}
                />
              ))}
            </ul>
            <CallToAction
              href="/make-an-appointment"
              className="mt-4 mb-24 xl:ml-8 xl:my-0 whitespace-nowrap w-max items-center flex relative z-20"
            >
              Make an Appointment
            </CallToAction>
          </nav>
        </div>
      </div>
    </header>
  )
}
