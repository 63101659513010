import Link from "next/link"
import cn from "classnames"
import { getStoryblokLink } from "utilities/getStoryblokLink"
import getTarget from "utilities/getTarget"

export default function MenuItem({ item, depth = 1, currentPath, currentOpenSubmenu, setSubMenuAccordionVisibility }) {
  const href = getStoryblokLink(item.link)

  const MobileAccordionToggle = () =>
    item?.nested_menu_items?.length > 0 && depth === 1 ? (
      <button
        aria-label="expand submenu"
        className="xl:hidden font-semibold p-2"
        onClick={(e) => {
          e.stopPropagation()
          setSubMenuAccordionVisibility((prev) => (prev !== item.label ? item.label : ""))
        }}
      >
        {currentOpenSubmenu === item.label ? "-" : "+"}
      </button>
    ) : null

  return (
    <li className={cn({ "col-span-3": href })}>
      {href ? (
        <Link
          prefetch={false}
          href={href}
          className={cn("cursor-pointer whitespace-nowrap", {
            "current-page": currentPath === href,
          })}
          target={getTarget(href)}
        >
          {item.label}
          <MobileAccordionToggle />
        </Link>
      ) : (
        <div
          className="nonlinked"
          onClick={(e) => {
            e.stopPropagation()
            if (depth === 1) {
              setSubMenuAccordionVisibility((prev) => (prev !== item.label ? item.label : ""))
            }
          }}
        >
          {item.label}
          <MobileAccordionToggle />
        </div>
      )}
      <SubMenu {...{ ...item, currentOpenSubmenu, currentPath, depth }} />
    </li>
  )
}

function SubMenu({ nested_menu_items, label, currentOpenSubmenu, depth, currentPath }) {
  return nested_menu_items.length ? (
    <ul
      aria-label={`${label} menu`}
      className={cn({
        closed: depth === 1 && currentOpenSubmenu !== label,
      })}
    >
      {nested_menu_items.map((subItem) => (
        <MenuItem currentPath={currentPath} depth={depth + 1} item={subItem} key={`menu-item-${subItem._uid}`} />
      ))}
    </ul>
  ) : null
}
