import NextLink from "next/link"
import cn from "classnames"
import getTarget from "utilities/getTarget"

export default function CallToAction({
  href,
  target = null,
  children,
  style = "primary",
  button = false,
  className,
  _uid, // prevent prop pollution
  label, // prevent prop pollution
  link, // prevent prop pollution
  ...delegated
}) {
  const ctaStyles = {
    primary:
      "primary-link px-5 py-3 bg-purple text-white no-underline font-primary uppercase hover:bg-blue-light hover:text-purple text-center inline-block",
    "secondary-purple":
      "secondary-link-purple px-5 py-3 text-purple no-underline font-primary uppercase border-purple border-y border-l relative mr-12 inline-block",
    "secondary-navy":
      "secondary-link-navy px-5 py-3 text-navy no-underline font-primary uppercase border-navy border-y border-l relative mr-12 inline-block",
    purple:
      "purple-link text-purple block underline hover:no-underline underline-offset-4 font-primary decoration-purple decoration-solid w-max",
    phone:
      "phone-link text-purple block mb-2 underline hover:no-underline underline-offset-4 font-primary decoration-purple decoration-solid",
    fax: "fax-link text-purple block mb-2 underline hover:no-underline underline-offset-4 font-primary decoration-purple decoration-solid",
    "map-pin":
      "map-pin-link text-purple block mb-2 underline hover:no-underline underline-offset-4 font-primary decoration-purple decoration-solid",
    share: "share-link block mb-2 underline hover:no-underline underline-offset-4 font-primary decoration-solid",
    inline:
      "inline-link inline-block underline hover:no-underline underline-offset-4 decoration-black decoration-solid",
  }
  const styles = cn(
    "cursor-pointer max-w-full transition-all duration-300", // default styles
    ctaStyles[style],
    className
  )

  if (button) {
    return (
      <button className={styles} {...delegated}>
        {children}
      </button>
    )
  }

  if (!href)
    return (
      <span data-type="invalid-link" className={styles}>
        {children}
      </span>
    )

  // Storyblok's link objects are inconsistent
  const targetValue = target ?? getTarget(href)

  // Use route-based navigation if internal, use a plain link otherwise
  return targetValue === "_self" ? (
    <NextLink href={href} data-type="route" target="_self" className={styles} {...delegated}>
      {children}
    </NextLink>
  ) : (
    <a data-type="external" className={styles} target={targetValue} rel="noreferrer" href={href} {...delegated}>
      {children}
    </a>
  )
}
