import { useState } from "react"
import Link from "next/link"
import cn from "classnames"
import { getStoryblokLink } from "utilities/getStoryblokLink"
import getTarget from "utilities/getTarget"
import getTelLink from "utilities/getTelLink"
import Facebook from "public/assets/facebook.svg"
import YouTube from "public/assets/youtube.svg"
import Twitter from "public/assets/twitter.svg"
import Image from "./Image"

export default function Footer({ phoneNumbers, socialMedia, footer }) {
  return (
    <footer className="bg-blue-light text-navy py-6 px-12 md:px-8">
      <div className="footer-grid mx-auto max-w-screen-xl border-b border-navy lg:py-6 items-start">
        <Link href="/" className="footer-logo mb-4 md:justify-self-center">
          <Image
            loader={undefined}
            className="w-full pointer-events-none"
            src="/assets/footer-logo@2x.png"
            width="300"
            height="144"
            alt="Sonoran Spine Logo - In Collaboration with Honor Health"
            title="Return to home page"
          />
          {/* eslint-disable-next-line */}
          {/* <img className="w-full max-w-[400px]" src={"/assets/footer-logo.svg"} title="Return to home page" /> */}
        </Link>
        <div className="footer-phone-numbers mb-4 lg:mb-0 lg:text-center">
          <Phone num={phoneNumbers?.Primary}>Phone</Phone>
          <Phone num={phoneNumbers?.Fax}>Fax</Phone>
        </div>
        <div className="footer-social-icons flex gap-6 md:mx-0 w-max md:justify-self-center md:-mt-16 lg:mt-0">
          <SocialIcon href={socialMedia?.Facebook} icon="facebook" />
          <SocialIcon href={socialMedia?.YouTube} icon="youtube" />
          <SocialIcon href={socialMedia?.Twitter} icon="twitter" />
        </div>
        {footer?.footerMenus?.map(({ id, name, content }) => (
          <AccordionMenu id="footer-quick-links" key={id} {...{ ...content, name }} />
        ))}
        <AccordionMenu id="footer-locations" menu_items={footer?.locations} name="Locations" />
      </div>
      <div className="max-w-screen-xl mx-auto md:flex md:justify-between">
        <p className="font-light py-2"> © {new Date().getFullYear()} Sonoran Spine, all rights reserved.</p>
        <div className="md:py-2">
          <Link href="/privacy-policy" className="underline hover:no-underline mr-8">
            Privacy Policy
          </Link>
          <Link href="/terms-of-use" className="underline hover:no-underline">
            Terms of Use
          </Link>
        </div>
      </div>
    </footer>
  )
}

const AccordionMenu = ({ menu_items, name, id }) => {
  const [open, toggle] = useState(false)

  const content = cn("md:max-h-screen transition-all overflow-hidden", {
    "max-h-screen transition-all": open,
    "max-h-0": !open,
    "md:grid md:grid-cols-2 md:gap-x-12": menu_items.length > 6,
  })

  return (
    <nav id={id} aria-label={name} className="accordion-menu mb-6">
      <button
        onClick={() => toggle((prev) => !prev)}
        className="footer-accordion-toggle font-semibold lg:text-m1 lg:font-bold cursor-pointer"
      >
        {name}{" "}
        <span className="md:hidden" aria-hidden>
          {open ? "-" : "+"}
        </span>
      </button>
      <ul className={content}>
        {menu_items.map((i, idx) => {
          const href = name !== "Locations" ? getStoryblokLink(i.link) : i.link
          return (
            <li key={idx}>
              <Link href={href} target={getTarget(href)} className="font-light pt-2 block hover:underline w-max">
                {i.label}
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

const Phone = ({ children, num }) =>
  num ? (
    <div className="pt-3 first:pt-0">
      <span>{children}: </span>
      <a className="underline hover:no-underline" href={getTelLink(num)}>
        {num}
      </a>
    </div>
  ) : null

function SocialIcon({ href, icon }) {
  const Icons = {
    facebook: <Facebook />,
    youtube: <YouTube />,
    twitter: <Twitter />,
  }
  const Icon = () => Icons?.[icon] ?? null

  return Icon && href ? (
    <Link
      href={href}
      target="_blank"
      aria-label={`${icon ? `${icon} ` : ""}social media logo`}
      className="icon flex gap-4 transition-all duration-200 mb-4"
    >
      <Icon />
    </Link>
  ) : null
}
