import Head from "next/head"
import { useRouter } from "next/router"
import { useNextRouterViewTransitions } from "use-view-transitions/next"

import "styles.css"
import "@fontsource/tenor-sans"
import "@fontsource/open-sans"
import "@fontsource/open-sans/latin-300.css"
import "@fontsource/open-sans/latin-600.css"
import "@fontsource/open-sans/latin-700.css"

import PreviewAlert from "components/PreviewAlert"
import Header from "components/Header"
import Footer from "components/Footer"

import useStoryblok from "storyblok/useStoryblok"

function MyApp({ Component, pageProps: { preview, story, resolve_relations, globals, ...remainingProps } }) {
  const router = useRouter()
  useNextRouterViewTransitions(router)
  story = useStoryblok({ story, resolve_relations })

  return (
    <>
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#603cba" />
        <meta name="theme-color" content="#72226D" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <link rel="preconnect" href="https://googletagmanager.com" />
        <link rel="preconnect" href="https://pagead2.googlesyndication.com" />
        <link rel="preconnect" href="https://connect.facebook.net" />
        <link rel="preconnect" href="https://https://a-us.storyblok.com/" />
      </Head>
      <svg xmlns="http://www.w3.org/2000/svg" height="0" width="0">
        <defs>
          <clipPath id="homeHeroSmall" clipPathUnits="objectBoundingBox">
            <path d="M0 0H1V.92S.938.934.886.957.778.998.684 1C.589 1 .487.925.487.925S.454.92.404.888C.347.858.274.836.224.84.082.849 0 .936 0 .936V0" />
          </clipPath>
          <clipPath id="homeHeroLarge" clipPathUnits="objectBoundingBox">
            <path d="M0 0H1V.92S.938.934.886.957.778.998.684 1C.589 1 .485.94.485.94S.454.92.404.888C.347.858.274.836.224.84.082.849 0 .936 0 .936V0" />
          </clipPath>
        </defs>
      </svg>
      {preview && <PreviewAlert />}
      <Header {...globals} />
      {/* <Transition> */}
      <Component story={story} {...remainingProps} />
      {/* </Transition> */}
      <Footer {...globals} />
    </>
  )
}

export default MyApp
